import React, { useCallback, useEffect, useState } from 'react';

import useLocalStorageFilters from '../../Hooks/useLocalStorageFilters';
import { AVAILABLE_DATE_CODES, availablePresets, getStartDateFromCode, getEndDateFromCode } from './dates';
import API from '../../Api';

import Text from '../../Components/Text';
import AnalyticsFiltersBar from '../../Components/AnalyticsFiltersBar';
import AnalyticsFiltersDate from '../../Components/AnalyticsFiltersDate';
import AnalyticsFiltersWebsite from '../../Components/AnalyticsFiltersWebsite';
import DashboardCharts from './DashboardCharts';
import { Error } from './styles';

const DashboardChartsMemo = React.memo(DashboardCharts);

function Dashboard () {
    // STATES
    const [ dateModalOpen, setDateModalOpen ] = useState(false);
    const [ websiteModalOpen, setWebsiteModalOpen ] = useState(false);

    const [ filters, setFilters ] = useLocalStorageFilters(AVAILABLE_DATE_CODES.TODAY, getStartDateFromCode(AVAILABLE_DATE_CODES.TODAY));
    
    const [ availableWebsites, setAvailableWebsites ] = useState({
        loading: false,
        error: false,
        list: []
    });

    const [ stats, setStats ] = useState({
        error: '',
        totalPageViews: 0,
        mostViewedPages: [],
        mostPopularReferrals: [],
        mostPopularUserAgents: [],
    })

    // STATISTICS FETCHING CALLBACKS
    const fetchStatsCallback = useCallback(async _ => {
        if (!filters.websiteId) {
            setStats(stats => ({ ...stats, error: 'You must select a website first.' }));
            return;
        }
        const url = `?website=${filters.websiteId}&startDate=${filters.startDate.getTime()}&endDate=${filters.endDate.getTime()}`
        try {
            const { data: stats } = await API.getOverviewStats(url);
            setStats({
                error: '',
                totalPageViews: stats.totalPageViews,
                uniquePageViews: stats.uniqueViews,
                interalPageViews: stats.internalViews,
                directPageViews: stats.directViews,
                mostViewedPages: stats.pages,
                mostPopularReferrals: stats.referrals,
                mostPopularUserAgents: stats.userAgents,
            });
        } catch (error) {
            console.error(error);
            setStats(stats => ({
                ...stats,
                error: 'An error occured while fetching websites data, please try again later.'
            }));
        }
    }, [ filters.websiteId, filters.startDate, filters.endDate ]);

    // TIME INTERVAL SETTINGS CALLBACKS
    const onStartDateChangeCallback = useCallback(startDate =>  setFilters({ dateCode: AVAILABLE_DATE_CODES.CUSTOM, startDate }), [ setFilters ]);
    const onEndDateChangeCallback = useCallback(endDate => setFilters({ dateCode: AVAILABLE_DATE_CODES.CUSTOM, endDate }), [ setFilters ]);
    const onDateCodeChangeCallback = useCallback(dateCode => setFilters({
        dateCode,
        startDate: getStartDateFromCode(dateCode),
        endDate: getEndDateFromCode(dateCode),
    }), [ setFilters ]);
    const onDateConfirmCallback = useCallback(_ => {
        setDateModalOpen(false);
        fetchStatsCallback();
    }, [ fetchStatsCallback ]);

    // WEBSITE SETTINGS CALLBACKS
    const onWebsitesModalOpenCallback = useCallback(async _ => {
        setWebsiteModalOpen(true);
        setAvailableWebsites(oldValues => ({ ...oldValues, loading: true }));
        try {
            const { data: result } = await API.listWebsites();
            setAvailableWebsites(oldValues => ({ ...oldValues, loading: false, list: result.data }));
        } catch (error) {
            console.error(error);
            setAvailableWebsites(oldValues => ({ ...oldValues, loading: false, error: true }));
        }
    }, []);
    const onWebsiteSelectCallback = useCallback(website => setFilters({ websiteDomain: website.domain, websiteId: website._id }), [ setFilters ]);
    const onWebsiteConfirmCallback = useCallback(_ => {
        setWebsiteModalOpen(false);
        fetchStatsCallback();
    }, [ fetchStatsCallback ]);

    // LIFECYCLE
    useEffect(_ => {
        if (!!filters.websiteId && !!filters.startDate && !!filters.endDate)
            fetchStatsCallback();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ ]);

    return (
        <>
            <AnalyticsFiltersWebsite
                visible={websiteModalOpen}
                onClose={_ => setWebsiteModalOpen(false)}
                loading={availableWebsites.loading}
                error={availableWebsites.error}
                currentId={filters.websiteId}
                websites={availableWebsites.list}
                onSelect={onWebsiteSelectCallback}
                onConfirm={onWebsiteConfirmCallback}
            />
            <AnalyticsFiltersDate
                visible={dateModalOpen}
                onClose={_ => setDateModalOpen(false)}
                availablePresets={availablePresets}
                dateCode={filters.dateCode}
                onDateCodeChange={onDateCodeChangeCallback}
                startDate={filters.startDate}
                onStartDateChange={onStartDateChangeCallback}
                endDate={filters.endDate}
                onEndDateChange={onEndDateChangeCallback}
                onConfirm={onDateConfirmCallback}
            />
            <AnalyticsFiltersBar
                timeInterval={`${filters.startDate?.toLocaleDateString()} - ${filters.endDate?.toLocaleDateString()}`}
                onTimeIntervalClick={_ => setDateModalOpen(true)}
                onWebsiteClick={onWebsitesModalOpenCallback}
                website={filters.websiteDomain}
            />
            { stats.error &&
                <Error>
                    <Text color="accent" size="medium">{stats.error}</Text>
                </Error>
            }
            { !stats.error && <DashboardChartsMemo
                totalPageViews={stats.totalPageViews}
                uniquePageViews={stats.uniquePageViews}
                interalPageViews={stats.interalPageViews}
                directPageViews={stats.directPageViews}
                mostViewedPages={stats.mostViewedPages}
                mostPopularReferrals={stats.mostPopularReferrals}
                mostPopularUserAgents={stats.mostPopularUserAgents}
            /> }
        </>
    )
};

export default Dashboard;