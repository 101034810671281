import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import Modal from '../Modal';
import { ButtonFilled, ButtonOutlined } from '../Button';
import Text from '../Text';
import { Group, Label, TextInput } from '../Input';
import Spinner from '../Loader';

function WebsiteCreateModal ({
    visible,
    onClose,
    onConfirm,
    loading = false,
    error = '',
}) {
    const [ domain, setDomain ] = useState('');

    const disableSubmit = !domain;

    const onDomainChange = event => setDomain(event.target.value);

    const getActions = useCallback(() => {
        if (loading)
            return <Spinner text="Processing" />;

        return [
            <ButtonOutlined
                key="cancel"
                onClick={onClose}
            >Cancel</ButtonOutlined>,
            <ButtonFilled
                key="create"
                disabled={disableSubmit}
                onClick={onConfirm}
            >Create</ButtonFilled>
        ];
    }, [loading, disableSubmit, onConfirm, onClose]);

    return (
        <Modal
            visible={visible}
            onClose={onClose}
            title="Create a new website"
            subtitle="Enter the domain of the new website to monitor"
            width="500px"
            height="150px"
            actions={getActions()}
        >
            <Group>
                <Label
                    htmlFor="domain"
                    color="main"
                    required
                >domain</Label>
                <TextInput
                    id="domain"
                    name="domain"
                    value={domain}
                    onChange={onDomainChange}
                    color="main"
                    autoFocus
                    required
                />
            </Group>
            {
                !!error &&
                <Text
                    color="accent"
                    size="medium"
                >{error}</Text>
            }
        </Modal>
    );
};

WebsiteCreateModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    error: PropTypes.string,
};

export default WebsiteCreateModal;