import React from 'react';

import { Container, Row } from './styles';
import { StatCardText, StatCardBarChart, StatCardDoughnutChart } from '../../Components/StatCard';

function DashboardCharts ({
    totalPageViews = 0,
    uniquePageViews = 0,
    interalPageViews = 0,
    directPageViews = 0,
    mostViewedPages = [],
    mostPopularReferrals = [],
    mostPopularUserAgents = [],
}) {

    const mostViewedPagesLabels = [];
    const mostViewedPagesData = [];
    const mostPopularReferralsLabels = [];
    const mostPopularReferralsData = [];
    const mostPopularUserAgentsLabels = [];
    const mostPopularUserAgentsData = [];

    mostViewedPages.forEach(page => {
        mostViewedPagesLabels.push(page._id);
        mostViewedPagesData.push(page.count);
    });
    mostPopularReferrals.forEach(referral => {
        mostPopularReferralsLabels.push(referral._id);
        mostPopularReferralsData.push(referral.count);
    });
    mostPopularUserAgents.forEach(userAgent => {
        mostPopularUserAgentsLabels.push(userAgent._id);
        mostPopularUserAgentsData.push(userAgent.count);
    });
    
    return (
        <Container>
            <Row>
                <div className="col-12 col-md-3">
                    <StatCardText
                        title="total page views"
                        value={totalPageViews}
                    />
                </div>
                <div className="col-12 col-md-3">
                    <StatCardText
                        title="unique views"
                        value={uniquePageViews}
                    />
                </div>
                <div className="col-12 col-md-3">
                    <StatCardText
                        title="direct views"
                        value={directPageViews}
                    />
                </div>
                <div className="col-12 col-md-3">
                    <StatCardText
                        title="internal views"
                        value={interalPageViews}
                    />
                </div>
            </Row>
            <Row>
                <div className="col-12 col-md-6">
                    <StatCardBarChart
                        title="most viewed pages"
                        labels={mostViewedPagesLabels}
                        data={mostViewedPagesData}
                        horizontal
                    />
                </div>
                <div className="col-12 col-md-6">
                    <StatCardBarChart
                        title="most popular referrals"
                        labels={mostPopularReferralsLabels}
                        data={mostPopularReferralsData}
                    />
                </div>
            </Row>
            <Row>
                <div className="col-12 col-md-6">
                    <StatCardDoughnutChart
                        title="most popular user agents"
                        labels={mostPopularUserAgentsLabels}
                        data={mostPopularUserAgentsData}
                    />
                </div>
            </Row>
        </Container>
    )
};

export default DashboardCharts;