import React, { useContext  } from 'react';
import { Check } from 'react-feather';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';

import Modal from '../Modal';
import Text from '../Text';
import { ButtonFilled } from '../Button';
import { WebsiteList, WebsiteListItem } from './styles';


const Contents = ({
    loading = false,
    error = false,
    websites = [],
    currentId = '',
    onSelect
}) => {
    const { colors } = useContext(ThemeContext);

    if (loading)
        return <Text>loading websites ...</Text>;
    
    if (error)
        return <Text size="medium" color="accent">An error occured while fetching the websites list</Text>;

    if (websites.length === 0)
        return <Text size="medium" color="accent">There are no websites available yet, create one in the websites section.</Text>;

    return (
        <WebsiteList>
            {
                websites.map(website => (
                    <WebsiteListItem
                        key={website._id}
                        onClick={_ => onSelect(website)}
                    >
                        <Text
                            color={ website._id === currentId ? 'accent' : 'primary'}
                            size="medium"
                            bold
                        >
                            {website.domain}
                            
                        </Text>
                        { website._id === currentId && <Check color={colors.accent} /> }
                    </WebsiteListItem>
                ))
            }
        </WebsiteList>
    );
};

const AnalyticsFiltersWebsite = ({
    visible = false,
    onClose,
    onConfirm,
    ...props
}) => {
    return (
        <Modal
            visible={visible}
            onClose={onClose}
            title="Current website"
            subtitle="Select the current website"
            actions={
                <ButtonFilled onClick={onConfirm}>Update</ButtonFilled>
            }
        >
            <Contents
                {...props}
            />
        </Modal>
    );
};

AnalyticsFiltersWebsite.propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    loading: PropTypes.bool,
    error: PropTypes.bool,
    websites: PropTypes.array,
    currentId: PropTypes.string,
    onSelect: PropTypes.func
};

export default AnalyticsFiltersWebsite;