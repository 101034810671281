import React, { useEffect, useRef, useContext, useMemo } from 'react';
import { ThemeContext } from 'styled-components';
import { lighten, darken } from 'polished';

import Chart from './charts';
import Text from '../Text';
import { Paper, Canvas, NoDataTextContainer } from './styles';

function StatCardBarChart ({
    title = '',
    labels = [],
    data = [],
}) {
    const canvas = useRef(null);
    const { colors } = useContext(ThemeContext);
    const backgroundColor = useMemo(_ => labels.map((l, i) => {
        if (i === 0)
            return colors.primary;

        if (i%2 === 0)
            return darken(0.1 * i, colors.primary)
        
        return lighten(0.1 * i, colors.primary)
    }), [ labels ]);

    const CONFIG = {
        options: {
            responsive: false,
            plugins: {
                tooltip: {
                    backgroundColor: colors.main,
                    titleColor: colors.white,
                    titleFont: { size: 16 },
                    bodyFont: { size: 16 },
                    displayColors: false
                },
            },
            elements: {
                bar: {
                    backgroundColor: colors.primary,
                }
            },
        }
    };

    useEffect(_ => {
        if (!data.length) return;

        const chartInstance = new Chart(canvas.current, {
            type: 'doughnut',
            data: {
                labels,
                datasets: [
                    {
                        data,
                        hoverBackgroundColor: colors.accent,
                        backgroundColor,
                    }
                ]
            },
            options: CONFIG.options
        });

        return function cleanUp () {
            chartInstance.destroy();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ labels, data ]);

    return (
        <Paper>
            <Text
                color="main"
                size="medium"
                uppercase
            >{title}</Text>
            { !!data.length && <Canvas center ref={canvas}></Canvas>}
            { !data.length && (
                <NoDataTextContainer>
                    <Text color="primary">No data available</Text>
                </NoDataTextContainer>)
            }
        </Paper>
    );
};

export default StatCardBarChart;