import React from 'react';

import {  TableHead as TableHeadDefault, TableHeadContents, TableData as TableDataDefault, TableDataIcons } from './styles';

export { Table, TableRow } from './styles';

export const TableHead = ({
    onClick,
    icon,
    children
}) => {
    if (!onClick)
        return <TableHeadDefault>{children}</TableHeadDefault>;

    return (
        <TableHeadDefault onClick={onClick}>
            <TableHeadContents>
                {children}
                {icon}
            </TableHeadContents>
        </TableHeadDefault>
    );
};

export const TableData = ({
    multy = false,
    children
}) => {
    if (!multy)
        return <TableDataDefault>{children}</TableDataDefault>;

    return (
        <TableDataDefault>
            <TableDataIcons>{children}</TableDataIcons>
        </TableDataDefault>
    );
};