import styled from 'styled-components';
import { Edit3 } from 'react-feather';

export const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 80px;
    z-index: 1;
    background-color: white;
    box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.15);
    width: 100%;
    height: 80px;
    padding: 5px 10px;
    padding-left: 16px;
    display: flex;
    align-items: center;

    ${({ theme }) => theme.mq.down(theme.mq.bp.tablet)} {
        flex-direction: column;
        align-items: flex-start;
        height: 140px;
        top: 50px;
        left: 0;
        padding-left: 10px;
    }
`;

export const Filter = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 200px;
`;

export const EditIcon = styled(Edit3).attrs(({ theme }) => ({
    color: theme.colors.primary
}))`
    margin-left: 8px;
`;

export const Separator = styled.div`
    height: 80%;
    width: 2px;
    background-color: ${({ theme }) => theme.colors.grey};
    margin: 0 25px;

    ${({ theme }) => theme.mq.down(theme.mq.bp.tablet)} {
        width: 80%;
        height: 2px;
        margin: 0 auto;
    }
`;