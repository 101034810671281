import styled from 'styled-components';

export const Row = styled.div.attrs({
    className: 'row'
})`
    height: 100vh;
`;

export const LeftSide = styled.div.attrs({
    className: 'col-12 col-md-6'
})`
    background-color: ${({ theme }) => theme.colors.primary};
`;

export const CenteredBox = styled.div`
    height: 100%;
    width: 50%;
    transform: translateX(50%);
    display: flex;
    flex-direction: column;
    justify-content: center;

    & > *:first-child {
        margin-bottom: 25px;
    }

    ${({ theme }) => theme.mq.down(theme.mq.bp.tablet)} {
        width: 100%;
        transform: none;
    }
`;

export const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
`;