import styled from 'styled-components';

export const PresetsList = styled.ul`
    list-style: none;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-bottom: 40px;
`;

export const PresetItem = styled.li`
    &:hover {
        & > button {
            color: ${({ theme }) => theme.colors.accent};
        }
    }
`;

export const PresetItemDivider = styled.li`
    height: 28px;
    width: 3px;
    background-color: ${({ theme }) => theme.colors.grey};
`;

export const CustomDate = styled.div`
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;

    .DayPicker-Months {
        flex-wrap: nowrap;
    }

    .DayPickerInput-OverlayWrapper {
        position: static;
    }

    .DayPickerInput-Overlay {
        left: 50%;
        transform: translateX(-50%);
    }
`;

export const CustomDateInput = styled.input.attrs({
    required: true
})`
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
    background-color: transparent;
    margin-left: 30px;
    padding: 5px 10px;
    font-size: 18px;
    font-weight: bold;
    width: 150px;
    color: ${({ theme }) => theme.colors.primary};

    &:focus {
        outline-color: ${({ theme }) => theme.colors.primary};
    }

    &::placeholder {
        color: ${({ theme }) => theme.colors.primary};
        font-weight: normal;
    }
`;