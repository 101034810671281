export const api = {
    baseUrl: process.env.REACT_APP_APIURL || 'localhost:4000/api',
    login: '/v1/auth/login',
    users: '/v1/users',
    websites: '/v1/websites',
    analytics: '/v1/analytics',
    companies: '/v1/companies',
};

export const localStorageKey = 'lytik-accessToken';

export const selectedWebsiteKey = 'lytik-selectedWebsite';

export const ROLES = {
    ADMIN: 100,
    OWNER: 90,
    USER: 80,
};