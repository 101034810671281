import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';

import Modal from '../Modal';
import { ButtonFilled, ButtonOutlined } from '../Button';
import Text from '../Text';
import { Group, Label, TextInput } from '../Input';
import Spinner from '../Loader';

function CompaniesCreateModal({
    visible,
    onClose,
    onConfirm,
    loading = false,
    error = '',
}) {
    const form = useRef(null);
    const [name, setName] = useState('');
    const [domain, setDomain] = useState('');
    const [email, setEmail] = useState('');

    const disableSubmit = !name || !domain || !email;

    const onNameChange = event => setName(event.target.value);
    const onDomainChange = event => setDomain(event.target.value);
    const onEmailChange = event => setEmail(event.target.value);
    const onCreateClick = _ => {
        if (form.current) {
            form.current.reportValidity();
            onConfirm({ domain, name, email });
        }
    };
    const getActions = useCallback(() => {
        if (loading)
            return <Spinner text="Processing" />;

        return [
            <ButtonOutlined
                key="cancel"
                onClick={onClose}
            >Cancel</ButtonOutlined>,
            <ButtonFilled
                key="create"
                disabled={disableSubmit}
                onClick={onCreateClick}
            >Create</ButtonFilled>
        ];
    }, [loading, disableSubmit, onCreateClick, onClose]);

    return (
        <Modal
            visible={visible}
            onClose={onClose}
            title="Invite a new company"
            subtitle="Enter the informations for the new company"
            width="500px"
            height="300px"
            actions={getActions()}
        >
            <form ref={form}>
                <Group>
                    <Label
                        htmlFor="name"
                        color="main"
                        required
                    >name</Label>
                    <TextInput
                        id="name"
                        name="name"
                        value={name}
                        onChange={onNameChange}
                        color="main"
                        autoFocus
                        required
                    />
                </Group>
                <Group>
                    <Label
                        htmlFor="domain"
                        color="main"
                        required
                    >domain</Label>
                    <TextInput
                        id="domain"
                        name="domain"
                        value={domain}
                        onChange={onDomainChange}
                        color="main"
                        required
                    />
                </Group>
                <Group>
                    <Label
                        htmlFor="email"
                        color="main"
                        required
                    >email</Label>
                    <TextInput
                        id="email"
                        name="email"
                        value={email}
                        onChange={onEmailChange}
                        color="main"
                        type="email"
                        required
                    />
                </Group>
            </form>
            {
                !!error &&
                <Text
                    color="accent"
                    size="medium"
                >{error}</Text>
            }
        </Modal>
    );
};

CompaniesCreateModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    error: PropTypes.string,
};

export default CompaniesCreateModal;