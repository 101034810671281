import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Briefcase, PieChart, Globe, LogOut } from 'react-feather';

import LateralNavigationMobile from './LateralNavigationMobile';

import GlobalContext from '../../services/GlobalContext';
import { ROLES } from '../../config';

import I18n from '../../i18n';

export default ({ onLogout }) => {
    const context = useContext(GlobalContext);

    return (
        <React.Fragment>
            <nav className="LateralNavigation">
                <ul>
                    <li className="LateralNavigation__Item">
                        <NavLink exact to="/profile" className="LateralNavigation__Link">
                            <div className="LateralNavigation__Item--Profile">
                                { context.user.email[0] && <p className="LateralNavigation__Letter">{context.user.email[0].toUpperCase()}</p> }
                            </div>
                            <span className="LateralNavigation__Label">{I18n.t.navbar.profile}</span>
                        </NavLink>
                    </li>

                    <li className="LateralNavigation__Item">
                        <NavLink exact to="/" className="LateralNavigation__Link">
                            <PieChart className="LateralNavigation__Icon" />
                            <span className="LateralNavigation__Label">Stats</span>
                        </NavLink>
                    </li>

                    <li className="LateralNavigation__Item">
                        <NavLink exact to="/websites" className="LateralNavigation__Link">
                            <Globe className="LateralNavigation__Icon" />
                            <span className="LateralNavigation__Label">Websites</span>
                        </NavLink>
                    </li>

                    {
                        context.user.role === ROLES.ADMIN &&
                        <li className="LateralNavigation__Item">
                            <NavLink exact to="/companies" className="LateralNavigation__Link">
                                <Briefcase className="LateralNavigation__Icon" />
                                <span className="LateralNavigation__Label">Companies</span>
                            </NavLink>
                        </li>
                    }
                </ul>

                <button className="LateralNavigation__Logout" type="button" onClick={onLogout}>
                    <LogOut className="LateralNavigation__Icon" />
                    <span className="LateralNavigation__Label">logout</span>
                </button>
            </nav>

            <LateralNavigationMobile
                context={context}
                onLogout={onLogout}
            />
        </React.Fragment>
    )
};