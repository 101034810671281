import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import Routes from './Routes';

import "./styles/Main.scss";

import I18n from './i18n';
// import * as serviceWorker from './serviceWorker';

I18n.init()
    .then(_ => ReactDOM.render(<Routes />, document.getElementById('root')))
    .catch(error => console.error(error));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
