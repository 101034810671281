import Axios from 'axios';

import { api, localStorageKey } from './config';

const axiosInstance = Axios.create({
    baseURL: api.baseUrl,
    timeout: 5000,
});

axiosInstance.interceptors.request.use(config => {
    config.headers['Authorization'] = 'Bearer ' + localStorage.getItem(localStorageKey);
    return config;
});

axiosInstance.interceptors.response.use(response => {
    return response;
  }, error => {

    if (error.response.status === 401) {
        localStorage.removeItem(localStorageKey);
        window.location.href = '/login';
    }

    return Promise.reject(error);
});

const API = {
    login: body => axiosInstance.post(api.login, body),
    getProfile: _ => axiosInstance.get(`${api.users}/me`),

    listWebsites: (query = '') => axiosInstance.get(api.websites + query),
    addWebsite: body => axiosInstance.post(api.websites, body),
    deleteWebsite: id => axiosInstance.delete(`${api.websites}/${id}`),

    createCompany: body => axiosInstance.post(api.companies, body),

    getOverviewStats: (query = '') => axiosInstance.get(api.analytics + query),
};

export default API;