import styled from 'styled-components';

export const WebsiteList = styled.ul`
    list-style-type: none;
`;

export const WebsiteListItem = styled.li`
    padding: 5px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    small {
        margin-left: 10px;
    }

    &:hover {
        cursor: pointer;

        & > p {
            color: ${({theme }) => theme.colors.accent};
        }
    }
`;