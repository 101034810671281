import React from 'react';

import Portal from './Portal';
import { Wrapper, Background, Contents, Close, Body, Actions } from './styles';
import Text from '../Text';

function Modal ({
    visible = false,
    onClose = _ => console.log('on close Modal'),
    width = '50%',
    height = '70vh',
    title = '',
    subtitle,
    actions,
    children
}) {

    return (
        <Portal>
            { visible &&
                <Wrapper>
                    <Contents width={width}>
                        <Close onClick={onClose} />
                        <Text color="primary" size="big" bold>{title}</Text>
                        { subtitle && <Text color="primary">{subtitle}</Text> }
                        <Body height={height}>{ children }</Body>
                        {actions && <Actions>{actions}</Actions>}
                    </Contents>
                    <Background onClick={onClose} />
                </Wrapper>
            }
        </Portal>
    );
};
export default Modal;