import styled from 'styled-components';

const SIZES = {
    small: '16px',
    medium: '18px',
    big: '24px',
    huge: '38px'
};

export const availableSizes = Object.keys(SIZES);

export const Text = styled.p`
    margin: 5px 0;
    font-size: ${({ $size }) => SIZES[$size] || SIZES.small};
    font-weight: ${({ $bold }) => $bold ? 'bold' : 'normal'};
    color: ${({ $color, theme }) => theme.colors[$color] || theme.colors.primary};
    text-transform: ${({ $uppercase }) => $uppercase ? 'uppercase' : 'none'};
`;