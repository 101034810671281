import React, { useCallback, useRef, useContext } from 'react';
import DayPickerInput from  'react-day-picker/DayPickerInput';
import { ThemeContext } from 'styled-components';

import Modal from '../Modal';
import Text from '../Text';
import { ButtonFilled, ButtonText } from '../Button';

import {
    PresetsList,
    PresetItem,
    PresetItemDivider,
    CustomDate,
    CustomDateInput
} from './styles';
import 'react-day-picker/lib/style.css';

const AnalyticsFiltersDate = ({
    visible = false,
    onClose,
    availablePresets = [],
    dateCode = '',
    onDateCodeChange,
    startDate,
    endDate,
    onStartDateChange,
    onEndDateChange,
    onConfirm
}) => {
    const { colors } = useContext(ThemeContext);
    const ToDatePicker = useRef(null);
    const today = new Date();

    const onSelectDateCallback = useCallback(date => {
        const day = date.getDate().toString().padStart(2, 0);
        const month = (date.getMonth() + 1).toString().padStart(2, 0);
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }, []);
    const onSelectDateFromCallback = useCallback(_ => ToDatePicker.current.getInput().focus(), [ ToDatePicker ]);

    const modifiers = {
        start: startDate,
        end: endDate,
        between: date => date < endDate && date > startDate
    };

    const modifiersStyles = {
        between: {
            borderRadius: 0,
            backgroundColor: colors.primaryLight
        },
        start: {
            borderRadius: 0,
            backgroundColor: colors.primary
        },
        end: {
            borderRadius: 0,
            backgroundColor: colors.primary
        }
    };

    return (
        <Modal
            visible={visible}
            onClose={onClose}
            title="Current time interval"
            subtitle="Select the current time interval"
            width="700px"
            actions={
                <ButtonFilled onClick={onConfirm}>Update</ButtonFilled>
            }
        >
            <PresetsList>
                { availablePresets.map((preset, index) => (
                    <React.Fragment key={preset}>
                        <PresetItem>
                            <ButtonText
                                onClick={_ => onDateCodeChange(preset)}
                                size="medium"
                                color={preset === dateCode ? 'accent' : 'primary'}
                                bold
                            >{preset}</ButtonText>
                        </PresetItem>
                        {index < availablePresets.length - 1 && <PresetItemDivider key={preset + '_divider'} />}
                    </React.Fragment>))
                }
            </PresetsList>
            <CustomDate>
                <Text
                    size="medium"
                    color={'custom' === dateCode ? 'accent' : 'primary'}
                    bold
                >custom</Text>
                    <DayPickerInput
                        component={CustomDateInput}
                        placeholder="from"
                        formatDate={onSelectDateCallback}
                        value={startDate}
                        dayPickerProps={{
                            selectedDays: [startDate, { from: startDate, to: endDate }],
                            disabledDays: { after: endDate < today ? endDate : today },
                            modifiers,
                            modifiersStyles,
                            numberOfMonths: 2,
                            onDayClick: onSelectDateFromCallback,
                        }}
                        onDayChange={onStartDateChange}
                    />

                    <DayPickerInput
                        component={CustomDateInput}
                        placeholder="to"
                        ref={ToDatePicker}
                        formatDate={onSelectDateCallback}
                        value={endDate}
                        dayPickerProps={{
                            selectedDays: [startDate, { from: startDate, to: endDate }],
                            disabledDays: { before: startDate, after: today },
                            modifiers,
                            fromMonth: startDate,
                            modifiersStyles,
                            numberOfMonths: 2,
                        }}
                        onDayChange={onEndDateChange}
                    />
            </CustomDate>

        </Modal>
    );
};

export default AnalyticsFiltersDate;

