import React, { useEffect, useState, useCallback } from 'react';
import { ArrowDown, ArrowUp, Trash } from 'react-feather';

import { PageContainer, PageHeading } from '../../Components/Layout';
import Text from '../../Components/Text';
import { ButtonFilled } from '../../Components/Button';
import { Table, TableRow, TableHead, TableData } from '../../Components/Table';
import CopyToClipboard from '../../Components/CopyToClipboard';
import WebsiteDeleteModal from '../../Components/WebsitesDeleteModal';
import WebsitesCreateModal from '../../Components/WebsitesCreateModal';

import API from '../../Api';

const ALREADY_EXISTING_ERROR = "A website with this domain already exists";
const GENERIC_ERROR = "An error occured during the creation, close the modal and try again.";

function Websites ({ history }) {
    const urlParams = new URLSearchParams(history.location.search);

    // STATES
    const [ sortDir, setSortDir ] = useState(parseInt(urlParams.get('sortDir')) || 1);
    const [ availableWebsites, setAvailableWebsites ] = useState({
        loading: false,
        error: false,
        list: []
    });
    const [ deleteWebsite, setDeleteWebsite ] = useState({
        modalOpen: false,
        domain: null,
        _id: null,
        error: false,
        loading: false
    });
    const [ createWebsite, setCreateWebsite ] = useState({
        modalOpen: false,
        loading: false,
        error: null
    });

    // CALLBACKS

    // domain list stuff
    const onDomainSortCallback = useCallback(_ => {
        setSortDir(oldSortDir => oldSortDir * -1);
        history.push({ search: `?sortDir=${sortDir * -1}` });
    }, [ sortDir, history ]);

    const fetchWebsitesCallback = useCallback(async search => {
        setAvailableWebsites({ loading: true, error: false, list: [] });

        try {
            const { data: result } = await API.listWebsites(search);
            setAvailableWebsites({ loading: false, error: false, list: result.data });
        } catch (error) {
            setAvailableWebsites({ loading: false, error: true, list: [] });
        }
    }, [ setAvailableWebsites ]);

    // delete website stuff
    const onDelete = website => _ => setDeleteWebsite({
        modalOpen: true,
        domain: website.domain,
        _id: website._id,
        loading: false,
        error: false
    });
    const onDeleteCancel = _ => setDeleteWebsite({
        modalOpen: false,
        domain: null,
        _id: null,
        loading: false,
        error: false
    });
    const onDeleteConfirmCallback = useCallback(async _ => {
        setDeleteWebsite(prevState => ({ ...prevState, loading: true, error: false }));

        try {
            await API.deleteWebsite(deleteWebsite._id);
            setDeleteWebsite({
                modalOpen: false,
                domain: null,
                _id: null,
                loading: false,
                error: false
            });
            fetchWebsitesCallback(history.location.search);
        } catch (error) {
            setDeleteWebsite(prevState => ({ ...prevState, loading: false, error: true }));
        }
    }, [ setDeleteWebsite, deleteWebsite, fetchWebsitesCallback, history.location.search ]);

    // create website stuff
    const onCreate = _ => setCreateWebsite({ modalOpen: true, loading: false, error: null });
    const onCancelCreate = _ => setCreateWebsite({ modalOpen: false, loading: false, error: null });
    const onCreateConfirmCallback = useCallback(domain => async _ => {
        setCreateWebsite(prevState => ({ ...prevState, loading: true }));

        try {
            await API.addWebsite({ domain });
            setCreateWebsite({ modalOpen: false, loading: false, error: null });
            fetchWebsitesCallback(history.location.search);
        } catch (error) {
            if (error.response?.status === 409 && error.response?.data?.code)
                setCreateWebsite({ modalOpen: true, loading: false, error: ALREADY_EXISTING_ERROR });
            else
                setCreateWebsite({ modalOpen: true, loading: false, error: GENERIC_ERROR });
        }
    }, [ setCreateWebsite, fetchWebsitesCallback, history.location.search ]);

    // LIFECYCLE
    useEffect(_ => {
        fetchWebsitesCallback(history.location.search);
    }, [ fetchWebsitesCallback, history.location.search ]);

    return (
        <>
            <WebsiteDeleteModal
                visible={deleteWebsite.modalOpen}
                onClose={onDeleteCancel}
                onConfirm={onDeleteConfirmCallback}
                loading={deleteWebsite.loading}
                domain={deleteWebsite.domain}
                error={deleteWebsite.error}
            />
            <WebsitesCreateModal
                visible={createWebsite.modalOpen}
                onClose={onCancelCreate}
                onConfirm={onCreateConfirmCallback}
                loading={createWebsite.loading}
                error={createWebsite.error}
            />
            <PageContainer>
                <PageHeading>
                    <div className="col-10">
                        <Text
                            size="huge"
                            bold
                        >Websites</Text>
                        <Text
                            size="big"
                        >List of all of all your websites</Text>
                    </div>
                    <div className="col-2">
                        <ButtonFilled onClick={onCreate}>Create one</ButtonFilled>
                    </div>
                </PageHeading>

                <div className="row">
                    <div className="col-12">
                        <Table>
                            <thead>
                                <TableRow>
                                    <TableHead
                                        onClick={onDomainSortCallback}
                                        icon={sortDir === 1 ? <ArrowDown /> : <ArrowUp />}
                                    >domain</TableHead>
                                    <TableHead>created at</TableHead>
                                    <TableHead>actions</TableHead>
                                </TableRow>
                            </thead>
                            <tbody>
                                {availableWebsites.list.map(website => (
                                    <TableRow key={website._id}>
                                        <TableData>{website.domain}</TableData>
                                        <TableData>{new Date(website.createdAt).toLocaleDateString()}</TableData>
                                        <TableData multy>
                                            <CopyToClipboard content={website.apikey} />
                                            <Trash onClick={onDelete(website)} />
                                        </TableData>
                                    </TableRow>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </PageContainer>
        </>
    )
};

export default Websites;