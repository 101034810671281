import styled from 'styled-components';

const SIZES = {
    small: '16px',
    medium: '18px',
    big: '24px',
    huge: '38px'
};

export const availableSizes = Object.keys(SIZES);

const Button = styled.button`
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:focus {
        outline: none;
    }

    &:disabled,
    &[disabled] {
        cursor: not-allowed;
        opacity: 0.8;
    }

    & > svg {
        height:  ${({ $size }) => SIZES[$size] || SIZES.small};
        width:  ${({ $size }) => SIZES[$size] || SIZES.small};
    }
`;

export const TextVariant = styled(Button)`
    background-color: transparent;
    margin: 5px 0;
    font-size: ${({ $size }) => SIZES[$size] || SIZES.small};
    font-weight: ${({ $bold }) => $bold ? 'bold' : 'normal'};
    color: ${({ $color, theme }) => theme.colors[$color] || theme.colors.primary};
`;

export const FilledVariant = styled(Button)`
    background-color: ${({ $color, theme }) => theme.colors[$color] || theme.colors.primary};
    color: white;
    padding: 8px 12px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
`;

export const OutlinedVariant = styled(Button)`
    background-color: transparent;
    border: 3px solid ${({ $color, theme }) => theme.colors[$color] || theme.colors.primary};
    color: ${({ $color, theme }) => theme.colors[$color] || theme.colors.primary};;
    padding: 8px 12px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
`;