import {
    Chart,
    CategoryScale,
    LinearScale,
    BarController,
    BarElement,
    DoughnutController,
    ArcElement,
    Legend,
    Tooltip,
} from 'chart.js';

Chart.register(CategoryScale, LinearScale, BarController, BarElement, DoughnutController, ArcElement, Legend, Tooltip);

Chart.defaults.font.family = "'Jost',sans-serif";
Chart.defaults.font.size = 16;

export default Chart;