import React from 'react';
import styled from 'styled-components';

import Text from '../Text';

const Container = styled.div`
    position: relative;
    height: 30px;
    padding-right: 40px;
`;

const Parent = styled.svg`
    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes dash {
        0% {
            stroke-dasharray: 1, 150;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -35;
        }
        100% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -124;
        }
    }

    @keyframes colors {
        0% {
        stroke: ${props => props.theme.colors.primary};
        }
        25% {
        stroke: ${props => props.theme.colors.primary};
        }
        26% {
        stroke: ${props => props.theme.colors.primary};
        }
        50% {
        stroke: ${props => props.theme.colors.primary};
        }
        51% {
        stroke: ${props => props.theme.colors.primary};
        }
        75% {
        stroke: ${props => props.theme.colors.primary};
        }
        76% {
        stroke: ${props => props.theme.colors.primary};
        }
        100% {
        stroke: ${props => props.theme.colors.primary};
        }
    }

    animation: rotate 2s linear infinite;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    width: 30px;
    height: 30px;

    & .path {
        stroke: hsl(210, 100, 75);
        stroke-linecap: round;
        animation: dash 1.5s ease-in-out infinite, colors 6s infinite;
    }
`;

function Spinner({ text = '' }) {
    return (
        <Container>
            { text && <Text size="small">{text}</Text>}
            <Parent viewBox="0 0 50 50">
                <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="2"></circle>
            </Parent>
        </Container>
    );
};

export default Spinner;