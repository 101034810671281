import React from 'react';
import PropTypes from 'prop-types';

import {
    Label as StyledLabel,
    Text as StyledText,
    Group as StyledGroup,
    availableSizes
} from './styles';

export const TextInput = ({ size, color, ...props }) => (
    <StyledText
        {...props}
        $color={color}
        $size={size}
    />
);

TextInput.propTypes = {
    size: PropTypes.oneOf(availableSizes),
    color: PropTypes.string,
};

export const Label = ({ size, color, required = false, children, ...props }) => (
    <StyledLabel
        {...props}
        $color={color}
        $size={size}
    >
        {children}
        { required && <span>*</span>}
    </StyledLabel>
);

Label.propTypes = {
    size: PropTypes.oneOf(availableSizes),
    color: PropTypes.string,
};

export const Group = ({ maxWidth = 'auto', children }) => (
    <StyledGroup $maxWidth={maxWidth}>{children}</StyledGroup>
);

Group.propTypes = {
    maxWidth: PropTypes.string,
};