export const AVAILABLE_DATE_CODES = {
    TODAY: 'today',
    YESTERDAY:'yesterday',
    LAST_WEEK: 'lastWeek',
    LAST_MONTH: 'lastMonth',
    CUSTOM: 'custom'
};

export const availablePresets = [
    AVAILABLE_DATE_CODES.TODAY,
    AVAILABLE_DATE_CODES.YESTERDAY,
    AVAILABLE_DATE_CODES.LAST_WEEK,
    AVAILABLE_DATE_CODES.LAST_MONTH,
];

export const getStartDateFromCode = code => {
    let date = new Date();
    switch (code) {
        case AVAILABLE_DATE_CODES.TODAY:
            break;

        case AVAILABLE_DATE_CODES.YESTERDAY:
            date.setDate(date.getDate() - 1);
            break;

        case AVAILABLE_DATE_CODES.LAST_WEEK:
            date.setDate(date.getDate() - 7);
            break;

        case AVAILABLE_DATE_CODES.LAST_MONTH:
            date.setMonth(date.getMonth() - 1);
            break;
    
        default:
            break;
    }

    return date;
}

export const getEndDateFromCode = code => {
    let date = new Date();
    switch (code) {
        case AVAILABLE_DATE_CODES.YESTERDAY:
            date.setDate(date.getDate() - 1);
            break;

        default:
            break;
    }

    return date;
}