import styled from 'styled-components';

export const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 2;
`;

export const Background = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    z-index: -1;
`;

export const Contents = styled.div`
    position: absolute;
    right: 50%;
    top: 50%;
    width: ${({ width }) => width};
    transform: translate(50%, -50%);
    padding: 15px 30px;
    background-color: white;
    border-radius: 5px;
    overflow-y: hidden;

    ${({ theme }) => theme.mq.down(theme.mq.bp.desktop)} {
        padding: 20px 15px;
        width: 70%;
    }

    ${({ theme }) => theme.mq.down(theme.mq.bp.tablet)} {
        padding: 20px 15px;
        width: 90%;
    }

`;

export const Close = styled.button.attrs({
    type: 'button'
})`
    position: absolute;
    right: 5px;
    top: 5px;
    height: 25px;
    width: 25px;
    border: none;
    background-color: transparent;
    transform: rotate(45deg);
    cursor: pointer;

    &::before, &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        height: 3px;
        background-color: ${({ theme }) => theme.colors.primary};
    }

    &::before {
        transform: rotate(90deg);
    }
`;

export const Body = styled.div`
    margin-top: 30px;
    overflow-y: auto;
    height: ${({ height }) => height};
`;

export const Actions = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 15px;

    & > * {
        margin-left: 10px;
    }
`;