import React, { useEffect, useState, useCallback } from 'react';

import { PageContainer, PageHeading } from '../../Components/Layout';
import Text from '../../Components/Text';
import { ButtonFilled } from '../../Components/Button';
import CompaniesCreateModal from '../../Components/CompaniesCreateModal';

import API from '../../Api';

const getAlreadyExistingError = field => `A company is already registered with the value specified in the field: ${field}`;
const GENERIC_ERROR = "An error occured during the creation, close the modal and try again.";

function Companies({ history }) {
    const [ createCompany, setCreateCompany ] = useState({
        modalOpen: false,
        loading: false,
        error: null
    });

    // create company stuff
    const onCreate = _ => setCreateCompany({ modalOpen: true, loading: false, error: null });
    const onCancelCreate = _ => setCreateCompany({ modalOpen: false, loading: false, error: null })
    const onCreateConfirmCallback = useCallback(async payload => {
        setCreateCompany(prevState => ({ ...prevState, loading: true }));
        try {
            await API.createCompany(payload);
            setCreateCompany(prevState => ({ ...prevState, loading: false, modalOpen: false }));
        } catch (error) {
            if (error.response?.status === 409 && error.response?.data?.code)
                setCreateCompany(prevState => ({
                    ...prevState,
                    loading: false,
                    error: getAlreadyExistingError(error.response?.data?.fieldName)
                }));
            else
                setCreateCompany(prevState => ({ ...prevState, loading: false, error: GENERIC_ERROR }));
        }
    });

    return (
        <>
            <CompaniesCreateModal
                visible={createCompany.modalOpen}
                loading={createCompany.loading}
                error={createCompany.error}
                onClose={onCancelCreate}
                onConfirm={onCreateConfirmCallback}
            />
            <PageContainer>
                <PageHeading>
                    <div className="col-10">
                        <Text
                            size="huge"
                            bold
                        >Companies</Text>
                        <Text
                            size="big"
                        >List of all of the companies registered</Text>
                    </div>
                    <div className="col-2">
                        <ButtonFilled onClick={onCreate}>Create one</ButtonFilled>
                    </div>
                </PageHeading>
            </PageContainer>
        </>
    );
};

export default Companies;