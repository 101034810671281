import React from 'react';
import PropTypes from 'prop-types';

import { TextVariant, FilledVariant, OutlinedVariant, availableSizes } from './styles';

export const ButtonText = ({ size, color, bold = false, children, ...props }) => (
    <TextVariant
        $size={size}
        $bold={bold}
        $color={color}
        {...props}
    >{children}</TextVariant>
);

export const ButtonFilled = ({ color, bold = false, children, ...props }) => (
    <FilledVariant
        $bold={bold}
        $color={color}
        {...props}
    >{children}</FilledVariant>
);

export const ButtonOutlined = ({ color, bold = false, children, ...props }) => (
    <OutlinedVariant
        $bold={bold}
        $color={color}
        {...props}
    >{children}</OutlinedVariant>
);


ButtonText.propTypes = {
    size: PropTypes.oneOf(availableSizes),
    color: PropTypes.string,
    bold: PropTypes.bool
};