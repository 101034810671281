import React, { useCallback, useState, useContext } from 'react';

import Text from '../../Components/Text';
import { TextInput, Label, Group } from '../../Components/Input';
import { ButtonFilled } from '../../Components/Button';
import { Row, LeftSide, CenteredBox, ButtonWrapper } from './styles';

import API from '../../Api';
import { localStorageKey } from '../../config';
import GlobalContext from '../../services/GlobalContext';

const LOGIN_ERROR = 'Wrong email/password combination.';
const GENERIC_ERROR = 'Wrong email/password combination.';

function Login ({ history }) {
    const globalContext = useContext(GlobalContext);
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ login, setLogin ] = useState({ loading: false, error: null });

    const onSubmitCallback = useCallback(async event => {
        event.preventDefault();

        setLogin({ loading: true, error: '' });

        try {
            console.log(email, password)
            const { data} = await API.login({ email, password });
            localStorage.setItem(localStorageKey, data.token);
            globalContext.setUser(data);
            history.push('/');
        } catch (error) {
            if (error.response?.data?.code === 'not_found' || error.response?.data?.code === 'not_authenticated')
                setLogin({ loading: false, error: LOGIN_ERROR });
            else
                setLogin({ loading: false, error: GENERIC_ERROR });
        }
    }, [ setLogin, email, password, history ]);

    return (
        <main className="container-fluid">
            <Row>
                <LeftSide>
                    <CenteredBox>
                        <Text color="white" size="huge">Lytik</Text>
                        <Text color="white" size="big">
                            the data you need, <br />
                            the privacy they deserve
                        </Text>
                    </CenteredBox>
                </LeftSide>
                <div className="col-12 col-md-6">
                    <CenteredBox>
                        <Text color="primary" size="huge">Login to continue</Text>
                        <form onSubmit={onSubmitCallback}>
                            <Group>
                                <Label htmlFor="email" required>email</Label>
                                <TextInput
                                    value={email}
                                    onChange={event => setEmail(event.target.value)}
                                    id="email"
                                    name="email"
                                    type="email"
                                    required
                                />
                            </Group>
                            <Group>
                                <Label htmlFor="password" required>password</Label>
                                <TextInput
                                    value={password}
                                    onChange={event => setPassword(event.target.value)}
                                    id="password"
                                    name="password"
                                    type="password"
                                    required
                                />
                            </Group>
                            <ButtonWrapper>
                                <ButtonFilled type="submit" disabled={login.loading}>login</ButtonFilled>
                            </ButtonWrapper>
                            { !!login.error && <Text color="accent">{login.error}</Text> }
                        </form>
                    </CenteredBox>
                </div>
            </Row>
        </main>
    )
};

export default Login;