const theme = {
    colors: {
        primary: '#2A9D8F', // green
        primaryLight: '#7FC4BB', // light green
        main: '#1D3557', // dark blue
        accent: '#F4A261', // orange
        grey: '#eee',
        white: '#ffffff'
    },
    mq: { // media queries
        bp: {
            iphone5: '320px',
            mobile: '321px',
            tablet: '768px',
            desktop: '992px',
            wide: '1200px',
        },
        up: breakpoint => `@media (min-width: calc(${breakpoint} + 0.02px))`,
        down: breakpoint => `@media (max-width: ${breakpoint})`,
        between: (breakpointMin, breakpointMax,) => `@media (max-width: ${breakpointMax}) and (min-width: calc(${breakpointMin} + 0.02px))`,
    }
};

export default theme;