import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Briefcase, PieChart, Globe, LogOut } from 'react-feather';

import { ROLES } from '../../config';

import { ReactComponent as HambugerIcon } from '../../images/menu.svg';
import { ReactComponent as CloseHamburgerIcon } from '../../images/close.svg';
import { ReactComponent as LogoutIcon } from '../../images/logout.svg';

import I18n from '../../i18n';

export default ({ context, onLogout }) => {

    const [ open, setOpen ] = useState(false);

    return (
        <nav className="LateralNavigation--Mobile">
            <NavLink to="/">logo</NavLink>
            <button className="LateralNavigation__Toggle" onClick={_ => setOpen(!open)}>
                { open ? <CloseHamburgerIcon style={{transform: 'rotate(-90deg)'}} /> : <HambugerIcon /> }
            </button>

            <ul className={ open ? "LateralNavigation__List LateralNavigation__List--Open" : "LateralNavigation__List"}>

                <li className="LateralNavigation__Item">
                    <NavLink exact to="/profile" className="LateralNavigation__Link--Mobile">
                        <div className="LateralNavigation__Item--Profile">
                            { context.user.email[0] && <p className="LateralNavigation__Letter">{context.user.email[0].toUpperCase()}</p> }
                        </div>
                        <span className="LateralNavigation__Label">{I18n.t.navbar.profile}</span>
                    </NavLink>
                </li>

                <li className="LateralNavigation__Item">
                    <NavLink exact to="/" className="LateralNavigation__Link--Mobile">
                        <PieChart className="LateralNavigation__Icon" />
                        <span className="LateralNavigation__Label">Stats</span>
                    </NavLink>
                </li>
                
                <li className="LateralNavigation__Item">
                    <NavLink exact to="/websites" className="LateralNavigation__Link--Mobile">
                        <Globe className="LateralNavigation__Icon" />
                        <span className="LateralNavigation__Label">Websites</span>
                    </NavLink>
                </li>

                {
                    context.user.role === ROLES.ADMIN &&
                    <li className="LateralNavigation__Item">
                        <NavLink exact to="/companies" className="LateralNavigation__Link--Mobile">
                            <Briefcase className="LateralNavigation__Icon" />
                            <span className="LateralNavigation__Label">Companies</span>
                        </NavLink>
                    </li>
                }

                <li className="LateralNavigation__Item">
                    <button className="LateralNavigation__Logout" type="button" onClick={onLogout}>
                        <LogOut className="LateralNavigation__Icon" />
                        <span className="LateralNavigation__Label">logout</span>
                    </button>
                </li>
            </ul>
        </nav>
    );
};