import React from 'react';

import Text from '../Text';
import { Paper } from './styles';

function StatCardText ({
    title = '',
    value = ''
}) {
    return (
        <Paper>
            <Text
                color="main"
                size="medium"
                uppercase
            >{title}</Text>
            <Text
                color="main"
                size="huge"
                bold
            >{value}</Text>
        </Paper>
    );    
}

export default StatCardText;