import styled from 'styled-components';

export const PageHeading = styled.div.attrs({
    className: 'row align-items-center'
})`
    margin-bottom: 50px;
`;

export const PageContainer = styled.main.attrs({
    className: 'container'
})`
    margin-bottom: 50px;
`;