import styled from 'styled-components';
import PropTypes from 'prop-types';

export const Paper = styled.div`
    border-radius: 15px;
    box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.15);
    padding: 8px 16px;
    width: 100%;
`;

export const Canvas = styled.canvas`
    margin: 10px ${({ center }) => center ? 'auto' : 0};
`;

Canvas.propTypes = {
    center: PropTypes.bool
};

export const NoDataTextContainer = styled.div`
    margin-top: 20px;
`;