import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Modal from '../Modal';
import { ButtonFilled, ButtonOutlined } from '../Button';
import Text from '../Text';
import Spinner from '../Loader';

function WebsiteDeleteModal ({
    visible,
    onClose,
    onConfirm,
    loading = false,
    error = false,
    domain = ''
}) {
    const getActions = useCallback(() => {
        if (loading)
            return <Spinner text="Processing" />;

        return [
            <ButtonOutlined
                key="cancel"
                onClick={onClose}
            >Cancel</ButtonOutlined>,
            <ButtonFilled
                key="create"
                onClick={onConfirm}
            >Delete</ButtonFilled>
        ];
    }, [loading, onConfirm, onClose]);

    return (
        <Modal
            visible={visible}
            onClose={onClose}
            title="Delete website"
            subtitle="Are you sure you want to delete the current website?"
            width="500px"
            height="150px"
            actions={getActions()}
        >
            <Text
                color="main"
                size="big"
                bold
            >{domain}</Text>
            { !!error && <Text
                color="accent"
                size="medium"
            >An error occured during the operation, please close this dialog and try again.</Text> }
        </Modal>
    );
};

WebsiteDeleteModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    error: PropTypes.bool,
    domain: PropTypes.string
};

export default WebsiteDeleteModal;