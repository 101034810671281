import styled from 'styled-components';

export const Table = styled.table`
    width: 100%;
    border-collapse: separate; 
    border-spacing: 5px;
`;

export const TableRow = styled.tr`
    text-align: left;
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.1);
`;

export const TableHead = styled.th`
    padding: 10px 5px;
    font-size: 18px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.primary};
`;

export const TableHeadContents = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;

    & > svg {
        margin-left: 10px;
    }
`;

export const TableData = styled.td`
    padding: 10px 5px;
    margin-bottom: 5px;
`;

export const TableDataIcons = styled.div`
    display: flex;
    align-items: center;

    & > svg {
        margin-left: 10px;
        cursor: pointer;

        &:hover {
            stroke: ${({ theme }) => theme.colors.accent};
        }
    }
`;