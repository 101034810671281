import { useEffect, useState } from 'react';

function loadInitialFilters (dateCode, endDate) {
    const rawFilters = localStorage.getItem('filters');
    if (!rawFilters)
        return {
            websiteId: null,
            websiteDomain: null,
            dateCode,
            startDate: new Date(),
            endDate
        };

    const filters = JSON.parse(rawFilters);
    filters.startDate = new Date(filters.startDate);
    filters.endDate = new Date(filters.endDate);
    return filters;
}

function useLocalStorageFilters (dateCode, endDate) {
    const [ filters, _setFilters ] = useState(loadInitialFilters(dateCode, endDate));

    useEffect(() => {
        localStorage.setItem('filters', JSON.stringify(filters));
    }, [ filters ]);

    const setFilters = newFilters => {
        _setFilters(filters => ({ ...filters, ...newFilters }));
    }

    return [ filters, setFilters ];
};

export default useLocalStorageFilters;