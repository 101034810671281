import styled from 'styled-components';

export const TooltipWrapper = styled.div`
    position: relative;
    width: 24px;
    cursor: pointer;

    & > svg:hover {
        color: ${({ theme }) => theme.colors.accent};
    }
`;

export const Tooltip = styled.div`
    position: absolute;
    bottom: -100%;
    left: 50%;
    transform: translateX(-50%);
    height: 30px;
    width: 115px;
    border-radius: 12px;
    background-color: ${({ theme }) => theme.colors.grey};
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
`;