import React from 'react';
import PropTypes from 'prop-types';

import { Text as TextWrapper, availableSizes } from './styles';

const Text = ({ size, color, bold = false, uppercase = false, children }) => (
    <TextWrapper
        $size={size}
        $bold={bold}
        $color={color}
        $uppercase={uppercase}
    >{children}</TextWrapper>
);

Text.propTypes = {
    size: PropTypes.oneOf(availableSizes),
    color: PropTypes.string,
    bold: PropTypes.bool,
    uppercase: PropTypes.bool
}

export default Text;