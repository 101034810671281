import styled from "styled-components";

export const Container = styled.div.attrs({
    className: 'container-fluid',
})`
    margin-top: 110px;
`;

export const Row = styled.div.attrs({
    className: 'row',
})`
    margin-bottom: 50px;
`;

export const Error = styled.div`
    margin-top: 130px;
    margin-left: 16px;
`;