import React, { useCallback, useState } from 'react';
import { Copy } from 'react-feather';

import { TooltipWrapper, Tooltip } from './styles';

function CopyToClipboard ({
    content = ''
}) {

    const [ visible, setVisible ] = useState(false);

    const onCopyCallback = useCallback(_ => {
        navigator.clipboard.writeText(content)
            .then(_ => {
                setVisible(true) 
                setTimeout(() => setVisible(false), 3000);
            })
            .catch(error => console.error(error));
    }, [ content ]);

    return (
        <TooltipWrapper>
            <Copy onClick={onCopyCallback} />
            { visible && <Tooltip>Apikey copied!</Tooltip> }
        </TooltipWrapper>
    );
};

export default CopyToClipboard;