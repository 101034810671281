import React from 'react';

export default React.createContext({
    // LOGGED IN USER CONTEXT
    user: {
        email: "",
        role: 0,
        fetching: false
    },
    hasAccessToken: () => false,
    setFetchingState: () => {},
    setUser: () => {},
    login: () => {},
    logout: () => {},
});