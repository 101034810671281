import React, { useState, useEffect, useContext } from 'react';
import { Route, Switch, Redirect, BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from 'styled-components';

// import LoginPage from './Pages/Login/Login';
import LoginPage from './Pages/Login';
import Dashboard from './Pages/Dashboard';
import Websites from './Pages/Websites';
import Companies from './Pages/Companies';

import DesktopNav from './Components/LateralNavigation/LateralNavigation';
import GlobalLoader from './Components/Loader/GlobalLoader';

import GlobalContext from './services/GlobalContext';
import theme from './services/theme';
import API from './Api';
import { localStorageKey } from './config';

const defaultUser = {
    email: "",
    role: 0,
    fetching: false
};

class App extends React.Component {

    state = {
        user: defaultUser
    }

    constructor (props) {
        super(props);
        this.setUser = this.setUser.bind(this);

        this.state.hasAccessToken = this.hasAccessToken;
        this.state.setUser = this.setUser;
    }

    render () {
        return (
            <GlobalContext.Provider value={this.state}>
                <ThemeProvider theme={theme}>
                    <Router>
                        <Route exact path="/login" component={LoginPage} />

                        <ProtectedRoutes />
                    </Router>
                </ThemeProvider>
            </GlobalContext.Provider>
        );
    }

    hasAccessToken () {
        return !!window.localStorage.getItem(localStorageKey);
    }

    setUser (user) {
        this.setState(prevState => ({ user: { ...prevState.user, ...user } }));
    }
};

function ProtectedRoutes (props) {
    const [ genericError, setGenericError ] = useState(false);
    const context = useContext(GlobalContext);

    const onLogout = _ => {
        window.localStorage.removeItem(localStorageKey);
        context.setUser(defaultUser);
    }

    useEffect(_ => {
        async function fetchProfile () {
            context.setUser({ fetching: true });
            if (genericError)
                setGenericError(false);

            try {
                const { data } = await API.getProfile();
                context.setUser({ ...data, fetching: false });
            } catch (error) {
                console.log(error);
                setGenericError(true);
            }
        }

        if (context.hasAccessToken() && !context.user.email && !context.user.fetching)
            fetchProfile();

    }, [context, genericError]);

    if (genericError)
        return <p>Generic error</p>;

    if (!context.hasAccessToken())
        return <Route {...props} render={_ => <Redirect to="/login" />} />;

    if (!context.user.email || context.user.fetching)
        return <GlobalLoader />;


    return (
        <main className="MainContents">
            <Switch>
                <React.Fragment>
                    <DesktopNav onLogout={onLogout} />
                    <Route exact path="/" component={Dashboard} />
                    <Route exact path="/websites" component={Websites} />
                    <Route exact path="/companies" component={Companies} />
                </React.Fragment>
            </Switch>
        </main>
    )
}

export default App;