import React from 'react';

import { Wrapper, Filter, EditIcon, Separator } from './styles';
import { ButtonText } from '../Button';
import Text from '../Text';

const AnalyticsFilters = ({
    website = '',
    timeInterval = '',
    onWebsiteClick,
    onTimeIntervalClick,
}) => (
    <Wrapper>
        <Filter>
            <Text color="main" size="small">Active website</Text>
            <ButtonText size="medium" bold onClick={onWebsiteClick}>
                {website || '-'}
                <EditIcon />
            </ButtonText>
        </Filter>

        <Separator />

        <Filter>
            <Text color="main" size="small">Active time interval</Text>
            <ButtonText size="medium" bold onClick={onTimeIntervalClick}>
                {timeInterval || '-'}
                <EditIcon />
            </ButtonText>
        </Filter>
    </Wrapper>
)

export default AnalyticsFilters;