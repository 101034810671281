import React, { useEffect, useRef, useContext } from 'react';
import { ThemeContext } from 'styled-components';

import Chart from './charts';
import Text from '../Text';
import { Paper, Canvas, NoDataTextContainer } from './styles';

function StatCardBarChart ({
    title = '',
    labels = [],
    data = [],
    horizontal = false,
}) {
    const canvas = useRef(null);
    const { colors } = useContext(ThemeContext);
 
    const CONFIG = {
        options: {
            responsive: true,
            indexAxis: horizontal ? 'y' : 'x',
            plugins: {
                legend: { display: false },
                tooltip: {
                    backgroundColor: colors.main,
                    titleColor: colors.white,
                    titleFont: { size: 16 },
                    bodyFont: { size: 16 },
                    displayColors: false
                },
            },
            elements: {
                bar: {
                    backgroundColor: colors.primary,
                }
            },
            scales: {
                x: {
                    grid: { display: false },
                    ticks: {
                        color: colors.main,
                        font: { size: 14 },
                        ...(!horizontal) && { callback: (index) => labels[index].length > 15 ? labels[index].substring(0, 15) + '...' : labels[index] }
                    }
                },
                y: {
                    grid: { display: false },
                    ticks: {
                        color: colors.main,
                        font: { size: 14 },
                        ...(horizontal) && { callback: (index) => labels[index].length > 15 ? labels[index].substring(0, 15) + '...' : labels[index] }
                    }
                }
            }
        }
    };

    useEffect(_ => {
        if (!data.length) return;

        const chartInstance = new Chart(canvas.current, {
            type: 'bar',
            data: {
                labels,
                datasets: [
                    {
                        data,
                        hoverBackgroundColor: colors.accent
                    }
                ]
            },
            options: CONFIG.options
        });

        return function cleanUp () {
            chartInstance.destroy();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ labels, data ]);

    return (
        <Paper>
            <Text
                color="main"
                size="medium"
                uppercase
            >{title}</Text>
            { !!data.length && <Canvas ref={canvas}></Canvas>}
            { !data.length && (
                <NoDataTextContainer>
                    <Text color="primary">No data available</Text>
                </NoDataTextContainer>)
            }
        </Paper>
    );
};

export default StatCardBarChart;